import React, { use } from 'react';
import PageLayout from '../layouts/page';
import type { FrontPage } from '../pages';
import Image from 'next/image';
import { useLuckyModal } from '../context/LuckyModalContext';
import { Button, Card, Dialog, Hero } from 'day8-ui';
import { CiCalendar, CiUser, CiGlobe } from "react-icons/ci";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { MdFormatQuote } from "react-icons/md";
import { RxCaretDown } from "react-icons/rx";

import ReviewsSlider from './ReviewsSlider';
import SignUp5050 from './SignUp5050';
import Link from 'next/link';
import { StopElement } from './Product2025Wrapper';
import Spacer from "../components/Spacer"
import CountdownText from './CountdownText';
import moment from 'moment';
import useHash from '../hooks/useHash';

const reviews: StopElement[] = [
  {
    excerpt: {
      rendered: "Yacht Week, yes it's worth it! Each day is filled with endless fun, from lively parties to the thrilling regatta. We met amazing people from all over the world, creating memories that will last a lifetime."
    },
    title: {
      rendered: "Jack"
    }
  },
  {
    excerpt: {
      rendered: "Experience of a lifetime, beyond all expectations. It isn't just a vacation; it's a journey into the lap of luxury and adventure on the high seas. Yacht Week caters to all, offering a perfect blend of relaxation and adventure."
    },
    title: {
      rendered: "Dejan"
    }
  },
  {
    excerpt: {
      rendered: "Best bucket list thus far. Every day I woke up and could not believe the dream I was living. Best time of my life. From the themed parties, the amazing staff, the most delicious food to the well-coordinated itinerary. Just wow."
    },
    title: {
      rendered: "Marianne"
    }
  },
  {
    excerpt: {
      rendered: "From the beauty of the islands to the warmth of the sun, and the energy of all involved it equated to a spectacular week. If you want the time of your life with great people and an amazing event crew, book Yacht Week."
    },
    title: {
      rendered: "Adam"
    }
  },
  {
    excerpt: {
      rendered: "After having my eye on Yacht Week trips for years, I was finally able to make it happen! From the day parties to the tunnel raft and everything in between, this trip was everything I thought it would be and more."
    },
    title: {
      rendered: "Raven"
    }
  },
  {
    excerpt: {
      rendered: "Yacht Week was literally the best week of my life. It was truly unique and \"is this real life\" type of experience that I am proud to have shared with the other amazing humans we met along the way."
    },
    title: {
      rendered: "Whitney"
    }
  }
]

const HomepageWrapper = ({ page, optionsPages }: FrontPage) => {
  const { toggle, setDefaultSection } = useLuckyModal();
  const [openModal, setOpenModal] = React.useState(false);
  const countdownDate = optionsPages.acf?.tyw_global_group?.countdown_banner || null;
  let isBookingOpen = false;
  if (countdownDate) {
    const parsedEndDate = moment.utc(countdownDate, "DD/MM/YYYY h:mm a");
    const now = moment.utc();
    const difference = parsedEndDate.diff(now);
    isBookingOpen = difference < 1;
  }
  useHash('#search', () => {
    setDefaultSection('where');
    toggle();
  });
  return (
    <div className="dui-bg-white dui-w-full block">
      <PageLayout page={page} canonical="https://www.theyachtweek.com/" signupHidden>
        <Hero
          video={{ url: 'https://cdn.theyachtweek.com/assets/video/2025/Homepage/Header/HomepageHeader1920x1080_720p.mp4' }}
          imageElement={<Image src="https://assets.theyachtweek.com/wp-content/uploads/2024/08/home-header.png" alt={"Yacht Week Presents"} width={1920} height={1080} />}
        >
          <Image src="https://assets.theyachtweek.com/wp-content/uploads/2024/08/ywpresents.png" alt="Yacht Week Presents" className='dui-mb-4 dui-m-auto' width={280} height={63} />
          <h1 className="dui-text-[58px] md:dui-text-[70px] lg:dui-text-[100px] dui-mb-2 dui-uppercase dui-text-white">
            EPIC SUMMER ESCAPES. <br />
            {isBookingOpen ? (
              <>
                NOW LIVE FOR 2025.
              </>
            ) : (
              <>
                LAUNCHing 10.10.24.
              </>
            )}

          </h1>
          {isBookingOpen && <Button className='dui-mx-auto dui-mt-4' onClick={toggle}>Book now</Button>}
          {/* Navbar offset 80... */}
          <AnchorLink className="dui-animate-heroScroll dui-flex dui-justify-center dui-mt-8" href="#welcome" offset={80}>
            <RxCaretDown className='dui-text-white dui-text-4xl' />
          </AnchorLink>
        </Hero>
        <div />
        {countdownDate && (
          <div className='dui-w-full dui-bg-primary-950 dui-p-4 dui-font-heading dui-text-white dui-text-center dui-uppercase'>
            <CountdownText endDate={countdownDate} />
          </div>
        )}

        <Spacer />

        <div id="welcome" />
        <div className="dui-container dui-mx-auto">
          <div className='dui-text-center'>
            <h2 className="dui-text-6xl dui-mb-6 dui-font-heading !dui-font-normal dui-text-primary-950 dui-uppercase">
              {isBookingOpen ? "CREATE YOUR PERFECT YACHT WEEK." : "Set sail on the best week of your life."}
            </h2>
            <div className='dui-max-w-4xl  dui-mx-auto'>
              <p className="dui-mb-8  dui-text-primary-950 ">
                {isBookingOpen ? "Stunning destinations. Unforgettable parties. A lifetime of memories. Put ordinary vacations to shame next summer." : "Stunning destinations. Unforgettable parties. A lifetime of memories. Escape your ordinary holiday next summer. Get up to 20% off early plans."}
              </p>
              {!isBookingOpen && <p className="dui-mb-8 font-bold dui-text-primary-950 ">Discover your perfect Yacht Week.</p>}
            </div>
          </div>

          <div className='dui-shadow-xl dui-p-4 dui-flex dui-gap-3 dui-flex-col lg:dui-flex-row'>
            <Button buttonType='SECONDARY' className='dui-shadow-md dui-flex dui-w-full !dui-items-start !dui-justify-start !dui-py-3 !dui-px-3' onClick={() => { setDefaultSection('where'); toggle() }}>
              <CiGlobe className="dui-mr-2" />
              <div className='dui-text-left'>
                <p className='dui-font-subheading dui-font-bold dui-mb-1 dui-leading-4'>WHERE?</p>
                <p className='dui-mb-0 dui-mt-2 dui-font-body dui-font-normal dui-text-sm dui-text-primary-dark-400 dui-normal-case'>Pick a destination</p>
              </div>
            </Button>

            <Button buttonType='SECONDARY' className='dui-shadow-md dui-flex dui-w-full !dui-items-start !dui-justify-start !dui-py-3 !dui-px-3' onClick={() => { setDefaultSection('when'); toggle() }}>
              <CiCalendar className="dui-mr-2" />
              <div className='dui-text-left'>
                <p className='dui-font-subheading dui-font-bold dui-mb-1 dui-leading-4'>WHEN?</p>
                <p className='dui-mb-0 dui-mt-2 dui-font-body dui-font-normal dui-text-sm dui-text-primary-dark-400 dui-normal-case'>Add a date</p>
              </div>
            </Button>

            <Button buttonType='SECONDARY' className='dui-shadow-md dui-flex dui-w-full !dui-items-start !dui-justify-start !dui-py-3 !dui-px-3' onClick={() => { setDefaultSection('who'); toggle() }}>
              <CiUser className="dui-mr-2" />
              <div className='dui-text-left'>
                <p className='dui-font-subheading dui-font-bold dui-mb-1 dui-leading-4'>WHO?</p>
                <p className='dui-mb-0 dui-mt-2 dui-font-body dui-font-normal dui-text-sm dui-text-primary-dark-400 dui-normal-case'>Add a crew</p>
              </div>
            </Button>

            <Button buttonType='PRIMARY' className='xl:dui-min-w-[250px] dui-w-full dui-justify-center !dui-h-16 lg:!dui-h-auto !dui-py-2 !dui-px-3 dui-font-subheading dui-font-bold dui-uppercase' onClick={() => toggle()}>
              Search
            </Button>
          </div>
        </div>

        <Spacer />

        <div className="dui-container dui-mx-auto">
          <Card className="!dui-p-0">
            <div className="dui-flex dui-flex-col md:dui-flex-row dui-w-full lg:dui-min-h-[400px]">
              <div className="dui-w-full lg:dui-w-1/2 dui-relative">
                <Image
                  alt="image"
                  className="dui-w-full dui-h-[400px] dui-min-h-full dui-object-cover"
                  src="https://assets.theyachtweek.com/wp-content/uploads/2024/09/TOFU-LP-FOR-THE-UNINITIATED-1.jpg"
                  height={800}
                  width={800}
                />
              </div>
              <div className="dui-w-full lg:dui-w-1/2 lg:dui-p-16 lg:dui-pr-32 dui-p-4 dui-bg-secondary-100 dui-flex dui-flex-col dui-justify-center dui-text-primary-950">
                <h3 className="dui-text-5xl dui-font-bold dui-mb-8 dui-text-primary-950 dui-uppercase">
                  {isBookingOpen ? "AN ODYSSEY TO THE EXTRAORDINARY." : "GET AHEAD OF THE QUEUE."}
                </h3>
                <p className="dui-mb-8 ">
                  {isBookingOpen ? "Yoga breakfasts in the Mediterranean. Swim stops in sapphire grottos. Raves on rafts under starry skies. It’s nothing like the real world." : "Be the first in line for yoga breakfasts in the Mediterranean. Secret swim stops in grottos. Raves on rafts under the stars."}
                </p>
                <p className="dui-mb-8 ">
                  {isBookingOpen ? "The adventure of a lifetime awaits. Dive into your personal odyssey." : "Shortlist your favorite trips before the 10th of October to unlock up to 20% off an extraordinary summer."}
                </p>
                {isBookingOpen ? (
                  <>
                    <Button
                      onClick={() => setOpenModal(true)}
                      buttonType="SECONDARY"
                      className="!dui-text-lg xl:dui-min-w-[250px] dui-self-start dui-font-subheading dui-font-bold dui-uppercase dui-w-full lg:dui-w-auto"
                    >
                      Watch video
                    </Button>
                    <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)} scrollable>
                      <div className='dui-relative'>
                        <iframe className="dui-aspect-video dui-object-cover dui-w-full" src="https://www.youtube.com/embed/HVgaaIl0Y3M?si=HSt7Co4WTPcFscIH" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <div>
                          <Button className='dui-w-full dui-mt-4' buttonType='SECONDARY' onClick={() => setOpenModal(false)}>Close</Button>
                        </div>
                      </div>
                    </Dialog>
                  </>
                ) : (
                  <Link href="/destinations-2025-g1"><Button
                    buttonSize="SMALL"
                    buttonType="SECONDARY"
                    className="!dui-text-lg xl:dui-min-w-[250px] dui-self-start dui-font-subheading dui-font-bold dui-uppercase dui-w-full lg:dui-w-auto"
                  >Browse trips</Button></Link>
                )}
              </div>
            </div>
          </Card>
        </div>

        <Spacer />

        <div className="dui-bg-primary-950 dui-px-2 md:dui-px-8">
          <Spacer />
          <div className='dui-container dui-mx-auto dui-text-center'>
            <div className='relative dui-w-1/5 dui-mx-auto dui-mb-6'>
              <div className='dui-h-[2px] dui-w-full dui-bg-secondary-500'></div>
              <div className='dui-bg-primary-950 dui-px-2 dui-flex dui-justify-center dui-items-center dui-absolute dui-left-1/2 dui-top-1/2 dui--translate-x-1/2 dui--translate-y-1/2'>
                <MdFormatQuote className='dui-text-white dui-text-4xl' />
              </div>
            </div>
            <h2 className='dui-text-5xl dui-leading-tight md:dui-text-[70px] lg:dui-text-[100px] !dui-font-normal dui-font-heading dui-uppercase dui-text-white'>an absolute 10 out of mother-effing 10.</h2>
            <div className='relative dui-w-1/5 dui-mx-auto dui-mt-8'>
              <div className='dui-h-[2px] dui-w-full dui-bg-secondary-500'></div>
              <div className='dui-bg-primary-950 dui-px-2 dui-flex dui-justify-center dui-items-center dui-absolute dui-left-1/2 dui-top-1/2 dui--translate-x-1/2 dui--translate-y-1/2'>
                <MdFormatQuote className='dui-text-white dui-text-4xl dui-rotate-180' />
              </div>
            </div>
          </div>
          <Image className='dui-mx-auto dui-max-w-1/2 dui-w-[200px] dui-mt-4 lg:dui-mt-12' src="https://assets.theyachtweek.com/wp-content/uploads/2024/08/cosmo-white-1.png" width="300" height="150" alt="Cosmopolitan" />
          <Spacer />
        </div>

        <Spacer />

        <div className="dui-container dui-mx-auto">
          <div className='dui-text-center dui-mb-8'>
            <h3 className="dui-text-6xl dui-font-heading !dui-font-normal dui-mb-6 dui-text-primary-950 dui-uppercase">Choose your own adventure</h3>
            <p className="dui-mb-4 dui-max-w-3xl dui-mx-auto ">The best of Yacht Week in original and new destinations. Expect the stunning views, gourmet meals on deck, picturesque islands and music that moves your soul.</p>
          </div>


          <div className='dui-flex dui-flex-col dui-gap-8 xl:dui-px-20 md:dui-flex-row dui-w-full lg:dui-min-h-[400px]'>
            <Card className="dui-w-full dui-p-4 md:dui-py-10 md:dui-px-14 !dui-bg-peach-50 dui-flex dui-flex-col dui-justify-center dui-text-primary-950 dui-border-solid dui-border-primary-950 dui-border-2 dui-text-center">
              <h3 className="dui-text-4xl dui-mb-4 dui-text-primary-950 dui-uppercase">Original floating festivals</h3>
              <div className='dui-h-[2px] dui-w-4/5 dui-mb-4 dui-mx-auto block dui-bg-primary-950'></div>
              <div className='dui-grow'>
                <p className="dui-mb-8 dui-max-w-2xl dui-mx-auto">Croatia, Greece, Sicily or the Caribbean? Choose one of our Original Festivals for the best vibes on earth. </p>
              </div>
              <div className='dui-flex dui-flex-col-reverse dui-gap-8'>
                <Link className="dui-flex dui-justify-center dui-w-full" href="/destination-guide#destinations"><Button
                  buttonSize="SMALL"
                  buttonType="PRIMARY"
                  className="!dui-text-lg xl:dui-min-w-[250px] dui-self-center dui-font-subheading dui-font-bold dui-mb-4 dui-w-full lg:dui-w-auto dui-uppercase"
                >Explore</Button></Link>
                <Image src="https://assets.theyachtweek.com/wp-content/uploads/2024/09/2025-Marketing-Drafts.png" alt="image" className="dui-w-full dui-h-[300px] dui-object-cover" width={400} height={400} />
              </div>
            </Card>

            <Card className="dui-w-full dui-p-4 md:dui-py-10 md:dui-px-14 !dui-bg-secondary-100 dui-flex dui-flex-col dui-justify-center dui-text-primary-950 dui-border-solid dui-border-primary-950 dui-border-2 dui-text-center">
              <h3 className="dui-text-4xl dui-mb-4 dui-text-primary-950 dui-uppercase">Special floating festivals</h3>
              <div className='dui-h-[2px] dui-w-4/5 dui-mb-4 dui-mx-auto block dui-bg-primary-950'></div>
              <div className='dui-grow'>
                <p className="dui-mb-8">Discover hand-picked DJs and exclusive parties with Special Festivals that celebrate hip-hop, disco, house and more.</p>
              </div>
              <div className='dui-flex dui-flex-col-reverse dui-gap-8 '>
                <Link className='dui-flex dui-justify-center dui-w-full' href="/destination-guide#festivals"><Button
                  buttonSize="SMALL"
                  buttonType="PRIMARY"
                  className="!dui-text-lg xl:dui-min-w-[250px] dui-self-center dui-font-subheading dui-font-bold dui-mb-4 dui-w-full lg:dui-w-auto dui-uppercase"
                >Explore</Button></Link>
                <Image src="https://assets.theyachtweek.com/wp-content/uploads/2024/09/Best-of-the-Best-2023-Croatia-Week-33-by-Emile-Hussell-3.png" alt="image" className="dui-w-full dui-h-[300px] dui-object-cover" width={400} height={400} />
              </div>
            </Card>
          </div>
        </div>

        <Spacer />


        <div className="dui-container dui-mx-auto">
          <div className='dui-text-left lg:dui-text-center'>
            <h3 className="dui-text-6xl dui-font-heading !dui-font-normal dui-mb-6 dui-text-primary-950 dui-uppercase">Put other holidays to shame.</h3>
            <p className="dui-mb-0  dui-max-w-4xl lg:dui-mx-auto">Swap out the ordinary for breathtaking riviera views and moonlit masquerade dances. <br />Leave behind the tame for a free-spirited, island hopping, beach-front partying, musically-charged you.
            </p>
          </div>
          <Spacer />
          <Card className="!dui-p-0 !dui-bg-transparent">
            <div className="dui-flex dui-flex-col md:dui-flex-row dui-w-full lg:dui-min-h-[400px]">
              <div className="dui-w-full lg:dui-w-1/2 dui-relative">
                {/* <Image
                  alt="image"
                  className="dui-w-full dui-h-[400px] dui-min-h-full dui-object-cover"
                  src="https://assets.theyachtweek.com/wp-content/uploads/2024/09/2025-marketing-drafts-1.png"
                  height={400}
                  width={400}
                /> */}
                <div className="dui-w-full dui-h-[400px] dui-min-h-full">
                  <video
                    className="dui-absolute dui-top-0 dui-left-0 dui-w-full dui-h-full dui-object-cover"
                    autoPlay
                    muted
                    loop
                    playsInline
                    poster="https://cdn.theyachtweek.com/assets/video/2025/Homepage/Gifs/Sail%20%2B%20explore/Sail%2BExplore1024x768-V10-MPEG-4_720p.png"
                  >
                    <source src="https://cdn.theyachtweek.com/assets/video/2025/Homepage/Gifs/Sail%20%2B%20explore/Sail%2BExplore1024x768-V10-MPEG-4_720p.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className="dui-w-full lg:dui-w-1/2 lg:dui-p-16 dui-px-4 dui-py-8 dui-flex dui-flex-col dui-justify-center dui-text-primary-950">
                <h3 className="dui-text-5xl dui-font-bold dui-mb-8 dui-text-primary-950 dui-uppercase">Sail + Explore</h3>
                <p className="">Hoist the mainsail, kick back and set out into the blue to discover something new. Immerse yourself in local culture, sample mouthwatering cuisine, stop off on secluded beaches and hike to heights that will make your heart soar.
                </p>
                {/* <Button
                  buttonSize="SMALL"
                  buttonType="SECONDARY"
                  className="xl:dui-min-w-[250px] dui-self-start dui-font-subheading dui-font-bold dui-w-full lg:dui-w-auto dui-uppercase"
                >Learn more</Button> */}
              </div>
            </div>
          </Card>

          <Card className="!dui-p-0 !dui-bg-transparent">
            <div className="dui-flex dui-flex-col-reverse md:dui-flex-row dui-w-full lg:dui-min-h-[400px]">

              <div className="dui-w-full lg:dui-w-1/2 dui-px-4 dui-py-8 lg:dui-p-16 dui-flex dui-flex-col dui-justify-center dui-text-primary-950">
                <h3 className="dui-text-5xl dui-font-bold dui-mb-8 dui-text-primary-950 dui-uppercase">Dance + Belong</h3>
                <p className="">Eat, sleep and live in a home that sails across the seas. Soundtrack your week with love, laughter and lush tunes. Deepen friendships and make lifelong connections with a crowd like no other.</p>
                {/* <Button
                  buttonSize="SMALL"
                  buttonType="SECONDARY"
                  className="xl:dui-min-w-[250px] dui-self-start dui-font-subheading dui-font-bold dui-w-full lg:dui-w-auto dui-uppercase"
                >Learn more</Button> */}
              </div>
              <div className="dui-w-full lg:dui-w-1/2 dui-relative">
                {/* <Image
                  alt="image"
                  className="dui-w-full dui-h-[400px] dui-min-h-full dui-object-cover"
                  src="https://assets.theyachtweek.com/wp-content/uploads/2024/09/TOFU-LP-EMBRACE-e1725616182255.jpg"
                  height={400}
                  width={400}
                /> */}
                <div className="dui-w-full dui-h-[400px] dui-min-h-full">
                  <video
                    className="dui-absolute dui-top-0 dui-left-0 dui-w-full dui-h-full dui-object-cover"
                    autoPlay
                    muted
                    loop
                    playsInline
                    poster="https://cdn.theyachtweek.com/assets/video/2025/Homepage/Gifs/Sail%20%2B%20explore/Sail%2BExplore1024x768-V10-MPEG-4_720p.png"
                  >
                    <source src="https://cdn.theyachtweek.com/assets/video/2025/Homepage/Gifs/Dancebelong_/DanceBelong.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className='dui-block dui-pb-10 lg:dui-pb-10'></div>
        <div className='dui-container dui-mx-auto dui-flex dui-justify-center'>
          <Link href="/destination-guide#destinations">
            <Button
              buttonSize="SMALL"
              buttonType="SECONDARY"
              className="!dui-text-lg xl:dui-min-w-[250px] dui-self-start dui-font-subheading dui-font-bold dui-w-full lg:dui-w-auto dui-uppercase"
            >Explore destinations</Button>
          </Link>
        </div>

        <Spacer />

        <ReviewsSlider reviews={reviews} />

        <Spacer />

        <div className='dui-mx-auto dui-container'>
          <div className='dui-w-full dui-mx-auto dui-border-solid dui-border-primary-950 dui-border-2 dui-pt-8'>
            <div className='dui-text-center dui-mb-0 dui-px-4'>
              <h3 className="dui-text-6xl dui-font-heading !dui-font-normal dui-mb-6 dui-text-primary-950 dui-uppercase">How it works</h3>
              <p className="dui-mb-8 lg:dui-mb-0 ">Gather your crew and set sail on the adventure of lifetime.</p>
            </div>
            <div className="dui-flex dui-flex-col lg:dui-flex-row ">
              <Card className="dui-w-full dui-p-4 md:dui-py-10 md:dui-px-12 !dui-bg-transparent dui-flex dui-flex-col dui-justify-center dui-text-primary-950 dui-text-center">
                <h3 className="dui-text-4xl dui-font-semibold dui-mb-4 dui-text-primary-950 dui-uppercase">The Destination</h3>
                <div className='dui-h-[2px] dui-w-full dui-mb-4 dui-mx-auto block dui-bg-primary-950'></div>
                <div className='dui-grow'>
                  <p className="dui-mb-4">Start by choosing a destination or a festival for a specific vibe and soundtrack.</p>
                </div>
                <Link href="/destination-guide#destinations"><Button
                  buttonSize="SMALL"
                  buttonType="PRIMARY"
                  className="!dui-text-lg dui-self-center dui-font-subheading dui-font-bold dui-w-full dui-mb-4 dui-uppercase"
                >Pick your route</Button></Link>
              </Card>
              <Card className="dui-w-full dui-p-4 md:dui-py-10 md:dui-px-12 !dui-bg-transparent dui-flex dui-flex-col dui-justify-center dui-text-primary-950 dui-text-center">
                <h3 className="dui-text-4xl dui-font-semibold dui-mb-4 dui-text-primary-950 dui-uppercase">The Yacht</h3>
                <div className='dui-h-[2px] dui-w-full dui-mb-4 dui-mx-auto block dui-bg-primary-950'></div>
                <div className='dui-grow'>
                  <p className="dui-mb-4">Choose sailing excitment or space to play. Every yacht comes with an expert skipper included.</p>
                </div>
                <Link href="/how-it-works/yachts"><Button
                  buttonSize="SMALL"
                  buttonType="PRIMARY"
                  className="!dui-text-lg dui-self-center dui-font-subheading dui-font-bold dui-w-full dui-mb-4 dui-uppercase"
                >Select your yacht</Button></Link>
              </Card>
              <Card className="dui-w-full dui-p-4 md:dui-py-10 md:dui-px-12 !dui-bg-transparent dui-flex dui-flex-col dui-justify-center dui-text-primary-950 dui-text-center">
                <h3 className="dui-text-4xl dui-font-semibold dui-mb-4 dui-text-primary-950 dui-uppercase">The Plan</h3>
                <div className='dui-h-[2px] dui-w-2/3 dui-mb-4 dui-mx-auto block dui-bg-primary-950'></div>
                <div className='dui-grow'>
                  <p className="dui-mb-8">No stress - easily spread your payments and split the costs between your crew.</p>
                </div>
                <Link href="/how-it-works/step-by-step"><Button
                  buttonSize="SMALL"
                  buttonType="PRIMARY"
                  className="!dui-text-lg dui-self-center dui-font-subheading dui-font-bold dui-w-full dui-mb-4 dui-uppercase"
                >How it works</Button></Link>
              </Card>
            </div>
          </div>
        </div>

        <Spacer />

        <SignUp5050 />

        <Spacer />
      </PageLayout>
    </div>
  );
};

export default HomepageWrapper;
